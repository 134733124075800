import React, { Fragment, useState, useEffect, useRef } from "react"
import Loading from "./loading"
import firebase from "gatsby-plugin-firebase"
import { InputText, Warning } from "./useInput"

export default function UpdatePaymentMethod() {
  const iframeRef = useRef(null);
  const [error, setError] = useState(undefined)
  const [iframeUrl, setIframeUrl] = useState(null)
  const [card, setCard] = useState(null)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isProcessed, setIsProcessed] = useState(false)

  useEffect(() => {
    let mounted = true
    try {
      if (firebase) {
        firebase
          .functions()
          .httpsCallable('getHostedPageUrl')().then((result) => {
            if (mounted) {
              setCard(result.data.card)
              setIframeUrl(result.data.url)
            }
          });
      } else {
        setError('Ooops! The service is not available for the moment. Please try again later.')
      }
    } catch (error) {
      setError(error.message)
    }
    return () => { mounted = false };
  }, [])

  useEffect(() => {
    let mounted = true
    function handleMessage(e) {
      try {
        //if (e.origin === "https://vault-sandbox.ezypay.com") {
        var response = JSON.parse(e.data)
        if (response.type) {
          if (response.type === "success") {
            setIsSubmitted(true)
            const requestData = {
              paymentMethodToken: response.data.paymentMethodToken
            }
            firebase
              .functions()
              .httpsCallable('savePaymentToken')(requestData).then(() => {
                if (mounted) {
                  setIsProcessed(true)
                }
              });
          }
        }
      } catch (error) {
        setError(error.message)
      }
      //}
    }
    if (iframeUrl !== null) {
      window.addEventListener("message", handleMessage);
      return () => { window.removeEventListener("message", handleMessage); mounted = false };
    } else
      return () => { mounted = false };
  }, [iframeUrl])

  function sendPayment() {
    var hostedpage = "https://vault.ezypay.com"
    var receiver = iframeRef.current.contentWindow
    receiver.postMessage({ actionType: "create" }, hostedpage)
  }

  return (
    <Fragment>
      {iframeUrl !== null && !isSubmitted ?
        <div className="w-full max-w-md">
          {card === null ? null :
            <Fragment>
              <div className="text-xl font-medium my-4">My current card</div>
              <InputText label="Card number" identifier="cardNumber" readOnly={true} variable={card.first6 + "..." + card.last4} maxLength="13" />
              <InputText label="Card expiry" identifier="cardExpiry" readOnly={true} variable={card.expiryMonth + "/" + card.expiryYear} maxLength="5" />
              <InputText label="Card name" identifier="cardName" readOnly={true} variable={card.accountHolderName} maxLength="100" />
            </Fragment>
          }
          <div className="text-xl font-medium my-4">Our fees</div>
          <table className="table-auto w-full text-xs border-b">
            <tbody>
              <tr className="border-t" key="feeFailedPayment">
                <td className="text-left py-2 pr-2">Failed payment fee</td>
                <td className="text-right py-2 pl-2">1.45%</td>
              </tr>
              <tr className="border-t" key="feeLoad">
                <td className="text-left py-2 pr-2">Load fee</td>
                <td className="text-right py-2 pl-2">2%</td>
              </tr>
              <tr className="border-t" key="feeCard">
                <td className="text-left py-2 pr-2">Standard Master/Visa fee</td>
                <td className="text-right py-2 pl-2">AUD 0.50 + 0.37%</td>
              </tr>
            </tbody>
          </table>
          <div className="text-xl font-medium mt-4">Update my card</div>
          <iframe title="EzyPay Payment Method" ref={iframeRef} src={iframeUrl} style={{ border: 0, height: isSubmitted ? "0px" : "740px" }} className="w-full"></iframe>
          <div className="flex flex-col flex-nowrap items-start">
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-black focus:outline-none bg-delidoor-light hover:bg-delidoor-dark"
              onClick={() => { sendPayment() }}
            >
              Update my payment method
            </button>
          </div>
        </div>
        :
        isProcessed ? null :
          <Loading text="We are processing your card details!" />
      }
      {!isProcessed ? null :
        <div className="grid place-items-center pt-10">
          <div className="text-xl text-center font-medium pb-5">
            Your new card has been saved.
          </div>
          <div className="pt-5 pb-10">
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24">
              <path className="fill-current text-delidoor-dark" d="M0 8v-2c0-1.104.896-2 2-2h18c1.104 0 2 .896 2 2v2h-22zm24 7.5c0 2.485-2.015 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 2.015-4.5 4.5-4.5 4.5 2.015 4.5 4.5zm-2.156-.882l-.696-.696-2.116 2.169-.991-.94-.696.697 1.688 1.637 2.811-2.867zm-8.844.882c0 1.747.696 3.331 1.82 4.5h-12.82c-1.104 0-2-.896-2-2v-7h14.82c-1.124 1.169-1.82 2.753-1.82 4.5zm-5 .5h-5v1h5v-1zm3-2h-8v1h8v-1z" />
            </svg>
          </div>
        </div>
      }
      {error === undefined ? null :
        <div className="max-w-md w-full">
          <Warning text={error} />
        </div>
      }
    </Fragment>
  );
}