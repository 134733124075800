import React from "react"
import ConfirmationModal from "./confirmationModal"
import firebase from "gatsby-plugin-firebase"
import Loading from "./loading";
export default function AccountHome({ activeScreen, setActiveScreen, profile, setProfile }) {
  const [loading, setLoading] = React.useState(false)
  const [showConfirmationPopup, setShowConfirmationPopup] = React.useState(false);
  const [ifSelectedNo, setIfSelectedNo] = React.useState(false);
  const [reminderWeek, setWeekReminder] = React.useState(null);

  return (
    loading ? <Loading text="Hold on tight!" /> :
      <>
        <div className="w-full max-w-md">
          <button
            type="button"
            className={"w-full my-4 p-4 rounded-md" + (activeScreen === 3 ? " bg-delidoor-dark" : " bg-delidoor-light hover:bg-delidoor-dark hover:bg-opacity-30")}
            onClick={() => { setShowConfirmationPopup(true) }}

          >
            <div className="text-md text-center">My next box</div>
          </button>
          <button
            type="button"
            className={"w-full my-4 p-4 rounded-md" + (activeScreen === 2 ? " bg-delidoor-dark" : " bg-delidoor-light hover:bg-delidoor-dark hover:bg-opacity-30")}
            onClick={() => { setActiveScreen(2) }}
          >
            <div className="text-md text-center">My profile</div>
          </button>
          <button
            type="button"
            className={"w-full my-4 p-4 rounded-md" + (activeScreen === 1 ? " bg-delidoor-dark" : " bg-delidoor-light hover:bg-delidoor-dark hover:bg-opacity-30")}
            onClick={() => { setActiveScreen(1) }}
          >
            <div className="text-md text-center">My delivery address</div>
          </button>
          <button
            type="button"
            className={"w-full my-4 p-4 rounded-md" + (activeScreen === 4 ? " bg-delidoor-dark" : " bg-delidoor-light hover:bg-delidoor-dark hover:bg-opacity-30")}
            onClick={() => { setActiveScreen(4) }}
          >
            <div className="text-md text-center">My payment method</div>
          </button>
          <button
            type="button"
            className={"w-full my-4 p-4 rounded-md" + (activeScreen === 5 ? " bg-delidoor-dark" : " bg-delidoor-light hover:bg-delidoor-dark hover:bg-opacity-30")}
            onClick={() => { setActiveScreen(5) }}
          >
            <div className="text-md text-center">My payment history</div>
          </button>
          {showConfirmationPopup &&
            <ConfirmationModal message="Are you ready to order now?" setWeekReminder={setWeekReminder} setShowConfirmationPopup={setShowConfirmationPopup} setActiveScreen={setActiveScreen} activeScreen={activeScreen} ifSelectedNo={ifSelectedNo} setIfSelectedNo={setIfSelectedNo} profile={profile} setProfile={setProfile} />
          }
          {
            ifSelectedNo && showConfirmationPopup &&
            <ConfirmationModal message="Remind me in" setIfSelectedNo={setIfSelectedNo} reminderWeek={reminderWeek} setWeekReminder={setWeekReminder} ifSelectedNo={ifSelectedNo} setShowConfirmationPopup={setShowConfirmationPopup} setActiveScreen={setActiveScreen} setLoading={setLoading} profile={profile} setProfile={setProfile} />
          }
          {((reminderWeek == 1 || reminderWeek == 2) && showConfirmationPopup) &&
            <ConfirmationModal message={`Sweet, you will receive a new meal box recomendation from us in ${reminderWeek} week`} setIfSelectedNo={setIfSelectedNo} reminderWeek={reminderWeek} setShowConfirmationPopup={setShowConfirmationPopup} setWeekReminder={setWeekReminder} setActiveScreen={setActiveScreen} activeScreen={activeScreen} profile={profile} setProfile={setProfile} />
          }

        </div>
      </>
  )
}