import React, { Fragment, useState, useEffect } from "react"
import firebase from "gatsby-plugin-firebase"
import Loading from "./loading"
import { Warning } from "./useInput"

export default function Invoices() {
  const [error, setError] = useState(undefined)
  const [invoices, setInvoices] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadNext, setLoadNext] = useState(false)
  const [nextPage, setNextPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const dateFormatOptions = { year: "2-digit", month: "numeric", day: "numeric" }
  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
  })

  useEffect(() => {
    let mounted = true

    const loadPage = () => {
      if ((nextPage === 0 && totalPages === 0) || (nextPage < totalPages)) {
        setLoading(true)
        const requestData = {
          cursor: nextPage
        }
        firebase
          .functions()
          .httpsCallable('listInvoicesPerCustomer')(requestData).then((result) => {
            if (mounted) {
              if (result.data.invoices !== undefined) {
                const mergedInvoices = invoices.concat(result.data.invoices)
                if (result.data.nextPage > nextPage)
                  setNextPage(result.data.nextPage)
                else
                  setNextPage(result.data.totalPages)
                setTotalPages(result.data.totalPages)
                setInvoices(mergedInvoices)
              } else {
                setError(result.data.message)
              }
              setLoading(false)
            }
          })
      }
    }

    loadPage()
    return () => { mounted = false };
  }, [loadNext])

  return (
    <div className="w-full max-w-md">
      {error === undefined ? null :
        <Warning text={error} />
      }
      {invoices.length === 0 ?
        <div className="text-xl text-center my-4">You don't have any invoices</div>
        :
        <Fragment>
          <div className="text-xl font-medium my-4">My payment history</div>
          <table className="table-auto w-full text-xs border-b">
            <thead className="hidden sm:table-header-group">
              <tr>
                <th className="text-left py-2 pr-2">Invoice date</th>
                <th className="text-left p-2">Email</th>
                <th className="text-right p-2">Amount (AUD)</th>
                <th className="text-right py-2 pl-2">Status</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice => {
                return (
                  <tr className="border-t" key={invoice.id}>
                    <td className="text-left py-2 pr-2 hidden sm:table-cell">{new Date(invoice.date).toLocaleDateString("en-AU", dateFormatOptions)}</td>
                    <td className="text-left py-2 break-all table-cell sm:hidden"><div><span className="font-semibold">Invoice date: </span>{new Date(invoice.date).toLocaleDateString("en-AU", dateFormatOptions)}</div><div><span className="font-semibold">Email: </span>{invoice.email}</div><div><span className="font-semibold">Amount (AUD): </span>{formatter.format(invoice.amount)}</div><div><span className="font-semibold">Status: </span>{invoice.status}</div></td>
                    <td className="text-left p-2 break-all hidden sm:table-cell">{invoice.email}</td>
                    <td className="text-right p-2 hidden sm:table-cell">{formatter.format(invoice.amount)}</td>
                    <td className="text-right py-2 pl-2 break-all hidden sm:table-cell">{invoice.status}</td>
                  </tr>
                )
              }))}
            </tbody>
          </table>
          {!loading ?
            <button
              type="button"
              className={"w-32 inline-flex text-base justify-center rounded-md border border-transparent shadow-sm px-4 py-2 mt-4 font-medium text-black focus:outline-none" + (nextPage >= totalPages ? " bg-gray-300" : " bg-delidoor-light hover:bg-delidoor-dark")}
              onClick={() => { if (nextPage < totalPages) setLoadNext(!loadNext) }}
            >
              Load more
            </button>
            :
            <Loading text="We are loading your invoices!" />
          }
        </Fragment>
      }
    </div>
  )
}