import React, { useState, Fragment } from "react"
import Profile from "../components/profile"
import SignUp from "../components/signup"
import Address from "../components/address"
import Products from "../components/products"
import Invoices from "../components/invoices"
import AccountHome from "../components/accountHome"
import UpdatePaymentMethod from "../components/updatePaymentMethod"
import LayoutAccount from "../components/layoutAccount"
import { CartProvider } from "../context/cartContext"
import defaultProfile from "../components/defaultProfile"
import firebase from "gatsby-plugin-firebase"
import Meta from "../components/meta"


export default function AccountPage() {
  const [profile, setProfile] = useState(() => { return defaultProfile() })
  const [postcodes, setPostcodes] = useState([])
  const [discounts, setDiscounts] = useState([])
  const [shippingFees, setShippingFees] = useState([])
  const [activeScreen, setActiveScreenState] = useState(0)

  const setActiveScreen = (screen) => {
    if (firebase.auth().currentUser !== null)
      setActiveScreenState(screen)
    else
      setActiveScreenState(0)
    window["scrollTo"]({ top: 0, behavior: "smooth" })
  }

  return (
    <CartProvider>
      <Meta title="Account" />
      <LayoutAccount activeScreen={activeScreen} setActiveScreen={setActiveScreen}>
        {(profile.cartStatus !== undefined) && (profile.cartStatus !== "notpaid") ?
          <Fragment>
            {(() => {
              switch (activeScreen) {
                case 1: return (<Address activeScreen={activeScreen} setActiveScreen={setActiveScreen} profile={profile} setProfile={setProfile} postcodes={postcodes} discounts={discounts} shippingFees={shippingFees} wizard={false} />)
                case 2: return (<Profile activeScreen={activeScreen} setActiveScreen={setActiveScreen} profile={profile} setProfile={setProfile} wizard={false} />)
                case 3: return (<Products activeScreen={activeScreen} setActiveScreen={setActiveScreen} profile={profile} setProfile={setProfile} setPostcodes={setPostcodes} discounts={discounts} setDiscounts={setDiscounts} shippingFees={shippingFees} setShippingFees={setShippingFees} wizard={false} />)
                case 4: return (<UpdatePaymentMethod />)
                case 5: return (<Invoices />)
                case 6: return (<AccountHome activeScreen={activeScreen} setActiveScreen={setActiveScreen} profile={profile} setProfile={setProfile}/>)
                default: return (<SignUp activeScreen={5} setActiveScreen={setActiveScreen} profile={profile} setProfile={setProfile} type="account" />)
              }
            })()}
          </Fragment>
          :
          <SignUp activeScreen={5} setActiveScreen={setActiveScreen} profile={profile} setProfile={setProfile} type="account" />
        }
      </LayoutAccount>
    </CartProvider>
  )
}