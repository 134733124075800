import React, { useState, useEffect, useRef } from "react"

export default function LayoutAccount({ children, activeScreen, setActiveScreen }) {
    const [showMenu, setShowMenu] = useState(false)
    const menuRef = useRef(null)
    const menuButtonRef = useRef(null)

    useEffect(() => {
        const onScroll = () => {
            setShowMenu(false)
        }

        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false)
            } else {
                if (menuButtonRef.current && menuButtonRef.current.contains(event.target)) {
                    setShowMenu(!showMenu)
                }
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        if (typeof window !== "undefined")
            window.addEventListener("scroll", onScroll);
        return () => {
            document.removeEventListener("click", handleClickOutside, true)
            if (typeof window !== "undefined")
                window.removeEventListener("scroll", onScroll)
        }
    }, [])

    return (
        <div className="flex justify-start h-screen">
            <div className="bg-white w-full xl:w-1/2 h-full">
                <div className="flex flex-col justify-between min-h-full">
                    <div className="flex flex-col">
                        <div className="flex justify-between cursor-pointer" onClick={() => { setActiveScreen(6); setShowMenu(false) }}>
                            <a className="p-8 no-mobile-outline">
                                <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 350" width="120">
                                    <path fillRule="evenodd" fill="#383839  " d="M158.1 39C175.5 39 176 39.1 178.7 41.4L181.5 43.8L181.5 303.2L178.7 305.6C176.2 307.7 174.9 308 167.1 308C155.8 308 154.3 306.9 151 296.6C149.7 292.4 148.3 289 147.8 289C147.4 289 147 289.4 147 289.9C147 291.6 133 301.9 126.7 304.9C108.3 313.6 87.3 313.5 68.5 304.6C46.3 294.1 30.6 272.5 26 246.4C24.2 236.2 25.2 215.6 28 206C34.1 184.9 48.3 167.2 66 158.7C78 153 86.9 151 100.8 151C111.1 151 117.9 152.2 132.8 156.6L135 157.2L135 43.1L137.6 41.1C140.1 39.2 141.6 39 158.1 39ZM95.3 193.5C71 201 61.1 232.5 76.1 254.9C84.7 267.8 99.5 273 114.8 268.5C121.3 266.5 129.9 258.8 133.4 251.8C136 246.7 136 246.3 136 223.4L136 200.1L129.8 197.2C126.3 195.5 121.1 193.7 118.2 193.1C111.2 191.6 100.5 191.8 95.3 193.5Z" />
                                    <path fillRule="evenodd" fill="#383839" d="M379.4 40.2C378.2 40.7 376.7 42.3 376.1 43.6C374.5 47.1 374.5 299.9 376.1 303.3C377.9 307.4 381 308 398.6 308C416.5 308 420.2 307.1 421.4 302.8C422.5 298.4 422.1 46.3 420.9 43.7C420.3 42.3 418.7 40.7 417.3 40.1C414.2 38.7 382.3 38.7 379.4 40.2Z" />
                                    <path fillRule="evenodd" fill="#a3ca81" d="M673.7 40C690.6 40 693.6 40.2 695.2 41.7C696.9 43.2 697 48.6 697 173.5L697 303.7L694.9 305.6C693.2 307.1 691 307.6 684.1 307.8C672.4 308.2 671.2 307.4 667.7 297.5C666.2 293.2 664.7 289.4 664.5 289.2C664.3 288.9 661.3 291.2 658 294.3C651.3 300.5 639 307.1 629.5 309.5C621.9 311.4 606.4 311.4 598 309.5C594.4 308.6 587.7 306.1 583 303.8C576 300.3 572.8 297.9 565 290.1C554.2 279.1 548.9 270.4 544.4 255.5C541.9 247.3 541.6 245.1 541.6 231C541.6 218.2 542 214.1 543.8 207.6C550 185.4 563.8 167.8 582.4 158.6C594.3 152.7 600.5 151.5 617.5 151.6C630.8 151.6 633.5 152 641.3 154.3C646.1 155.8 650.2 157 650.5 157C650.8 157 651 131.7 651 100.7C651 51.4 651.2 44.2 652.6 42.2C654.1 40.1 654.6 40 673.7 40ZM608.9 194.3C598.8 198.5 593.1 204.1 588.3 214.7C585.1 221.7 584.1 233.1 586 241.2C590.7 261 607 272.7 625.6 269.8C636 268.2 644.5 261.9 649.5 252.1C652 247.1 652 246.7 652 223.6L652 200L647 197.5C644.3 196.1 639.5 194.3 636.3 193.5C627.5 191.2 615.7 191.6 608.9 194.3Z" />
                                    <path fillRule="evenodd" fill="#383839" d="M305.2 64.1C299.3 66.4 239 103.2 238 105C237.1 106.7 237.7 108.7 241.3 116.4C246.3 126.8 247.6 127.8 253.6 125.6C265.5 121.4 321.8 95.7 323.3 93.8C324.5 92.4 325 90.6 324.6 89C323.9 85.4 313.4 66.9 310.9 64.7C308.5 62.8 308.4 62.8 305.2 64.1Z" />
                                    <path fillRule="evenodd" fill="#383839" d="M473.5 65.6C463.6 69.1 456.6 79.4 456.6 90.4C456.7 110.2 476.4 122.4 494.2 113.7C504.4 108.7 509.8 99.2 508.7 88C508.1 80.9 505.2 75.2 499.9 70.7C492.6 64.4 482.4 62.4 473.5 65.6Z" />
                                    <path fillRule="evenodd" fill="#383839" d="M301.4 153C333 161.1 354 188.5 354 221.6C354 232.9 353 236.2 349.3 237.9C347.7 238.7 331.6 239 297.5 239L248.1 239L248.6 242.2C249.6 248.2 254.9 257.6 259.6 262C272.8 274.1 291.7 275.2 310.8 265C321.6 259.1 322 259.2 332.3 271C343.2 283.4 344 285.1 341.3 288.8C336.3 295.6 319.5 305.3 307.4 308.4C262.5 319.9 222.3 298.6 208.4 256C205.8 248.2 205.6 246.2 205.6 232C205.5 213.6 206.6 208.3 214 193.4C224.2 172.7 243.6 157.2 265.5 152.5C275 150.4 292.3 150.6 301.4 153ZM253.5 205.2C250.5 212.4 248.8 212 281 212L310 212L309.4 209.2C307.3 199.5 298.8 189.8 290 187.1C275.1 182.7 259.8 190.3 253.5 205.2Z" />
                                    <path fillRule="evenodd" fill="#a3ca81" d="M814.2 152.5C829.7 155.7 841.2 162.2 853.5 174.5C860.8 181.7 863.5 185.3 867.2 192.5C874.9 207.4 876.5 214.1 876.5 230.5C876.5 242 876.1 245.9 874.2 252.3C868 273.9 853 292.7 834.2 302.3C820.2 309.5 815.8 310.5 798.5 310.5C784.7 310.4 782.8 310.2 775.5 307.7C754.8 300.5 736.3 283.5 727.4 263.5C721.6 250.2 719.4 230.3 722.1 215.4C727.5 186.2 752.2 160.1 781.1 153C790.2 150.7 805 150.5 814.2 152.5ZM787.8 193.4C777.4 197.1 770.6 203.4 765.6 214C762.7 220 762.5 221.3 762.5 231C762.5 240.7 762.7 242 765.7 248.2C776.9 271.8 804.8 277.6 823.3 260.1C844.4 240.1 837.7 204.1 811 193.9C805.1 191.7 793.4 191.4 787.8 193.4Z" />
                                    <path fillRule="evenodd" fill="#a3ca81" d="M1029.6 183.5C1040 198.2 1044.4 211.9 1044.4 230.5C1044.5 244.2 1043.3 250.4 1038.6 262.2C1029.1 285.7 1007.4 304.1 982.7 309.5C971 312.1 954.2 311.2 943.2 307.5C917.7 298.9 897.8 277.2 890.9 250.5C888 239.4 888.1 222.5 890.9 211.5C898.6 182.1 920.5 160.1 949 153C978.6 145.7 1011.4 158.1 1029.6 183.5ZM954.9 193.6C944.7 197.1 935 207.5 931.5 218.5C930.2 222.7 929.9 226.4 930.2 233C930.6 240.7 931.1 242.8 934.3 249.3C950.8 282.7 996.2 274.6 1003.2 237.1C1004.6 229.5 1003.2 220.4 999.4 212.6C996.1 205.8 987.8 197.7 981.3 194.8C974.9 191.9 961.4 191.3 954.9 193.6Z" />
                                    <path fillRule="evenodd" fill="#a3ca81" d="M1132.1 152.5C1123.5 154.9 1117 158.9 1109.1 166.8C1105 170.8 1101.6 173.9 1101.5 173.8C1101.4 173.6 1100.5 171 1099.5 168C1097.4 161.5 1095.1 156.8 1093.6 155.8C1093 155.4 1087.9 155 1082.3 155C1066.3 155 1068 146 1068 230.7C1068.1 288 1068.3 302.8 1069.4 304.2C1071.7 307.2 1075.8 308 1090.3 308C1104.6 308 1110.7 307.1 1112.2 304.8C1112.6 304.1 1113 281.8 1113 255.2L1113 206.9L1117.4 202.2C1124.3 194.9 1129.8 192.5 1139.5 192.6C1143.9 192.7 1149.8 193 1152.6 193.5C1157.5 194.2 1157.8 194.1 1159.7 191.4C1164.9 183.8 1175 164.8 1175 162.5C1175 159.1 1171.1 155.6 1165.2 153.5C1158.5 151.1 1139.2 150.5 1132.1 152.5Z" />
                                    <path fillRule="evenodd" fill="#383839" d="M464.3 155C463.1 155.5 461.7 156.8 461.1 157.9C460.3 159.2 460 182.7 460 231.5L460 303.1L462.5 305.6L465 308.1L483.6 307.8C501.7 307.5 502.2 307.4 504.1 305.1C505.9 302.9 506 300.2 506 231C506 161.8 505.9 159.1 504.1 156.9C502.2 154.6 501.8 154.5 484.3 154.3C474.5 154.2 465.5 154.5 464.3 155Z" />
                                </svg>
                            </a>
                            <div ref={menuButtonRef} className="p-8 cursor-pointer no-mobile-outline">
                                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24">
                                    <path fill="#a3ca81" d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 22c-3.123 0-5.914-1.441-7.749-3.69.259-.588.783-.995 1.867-1.246 2.244-.518 4.459-.981 3.393-2.945-3.155-5.82-.899-9.119 2.489-9.119 3.322 0 5.634 3.177 2.489 9.119-1.035 1.952 1.1 2.416 3.393 2.945 1.082.25 1.61.655 1.871 1.241-1.836 2.253-4.628 3.695-7.753 3.695z" />
                                </svg>
                            </div>
                        </div>
                        {!showMenu ? null :
                            <div ref={menuRef} className="absolute top-20 right-0 xl:right-1/2 flex flex-col items-end px-8 z-50">
                                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="18" viewBox="0 0 24 12">
                                    <path className="fill-current text-delidoor-light" stroke="fill-current text-delidoor-light" d="M24 12h-24l12-12z" />
                                </svg>
                                <div ref={menuRef} className="bg-delidoor-light shadow-lg flex flex-col items-end">
                                    <button
                                        type="button"
                                        className={"w-full p-4" + (activeScreen === 6 ? " bg-delidoor-dark" : " hover:bg-delidoor-dark hover:bg-opacity-30")}
                                        onClick={() => { setActiveScreen(6); setShowMenu(false) }}
                                    >
                                        <div className="text-md text-right">Account home</div>
                                    </button>
                                    <button
                                        type="button"
                                        className={"w-full p-4" + (activeScreen === 3 ? " bg-delidoor-dark" : " hover:bg-delidoor-dark hover:bg-opacity-30")}
                                        onClick={() => { setActiveScreen(3); setShowMenu(false) }}
                                    >
                                        <div className="text-md text-right">My next box</div>
                                    </button>
                                    <button
                                        type="button"
                                        className={"w-full p-4" + (activeScreen === 2 ? " bg-delidoor-dark" : " hover:bg-delidoor-dark hover:bg-opacity-30")}
                                        onClick={() => { setActiveScreen(2); setShowMenu(false) }}
                                    >
                                        <div className="text-md text-right">My profile</div>
                                    </button>
                                    <button
                                        type="button"
                                        className={"w-full p-4" + (activeScreen === 1 ? " bg-delidoor-dark" : " hover:bg-delidoor-dark hover:bg-opacity-30")}
                                        onClick={() => { setActiveScreen(1); setShowMenu(false) }}
                                    >
                                        <div className="text-md text-right">My delivery address</div>
                                    </button>
                                    <button
                                        type="button"
                                        className={"w-full p-4" + (activeScreen === 4 ? " bg-delidoor-dark" : " hover:bg-delidoor-dark hover:bg-opacity-30")}
                                        onClick={() => { setActiveScreen(4); setShowMenu(false) }}
                                    >
                                        <div className="text-md text-right">My payment method</div>
                                    </button>
                                    <button
                                        type="button"
                                        className={"w-full p-4" + (activeScreen === 5 ? " bg-delidoor-dark" : " hover:bg-delidoor-dark hover:bg-opacity-30")}
                                        onClick={() => { setActiveScreen(5); setShowMenu(false) }}
                                    >
                                        <div className="text-md text-right">My payment history</div>
                                    </button>
                                    <div className="bg-white h-1 w-full bg-opacity-50" />
                                    <a
                                        className="w-full p-4 hover:bg-delidoor-dark hover:bg-opacity-30"
                                        href="/getstarted"
                                    >
                                        <div className="text-md text-right">Get started</div>
                                    </a>
                                </div>
                            </div>
                        }
                        <div className="flex justify-center w-full px-8">
                            {children}
                        </div>
                    </div>
                    <div className="flex flex-nowrap justify-center px-4 pb-4 pt-12">
                        <div className="text-xs">Délidoor © {new Date().getFullYear()}</div>
                        <div className="text-xs mx-2">|</div>
                        <div className="text-xs"><a href="https://delidoor.com.au/policies/privacy-policy" className="underline">Privacy</a></div>
                        <div className="text-xs mx-2">|</div>
                        <div className="text-xs"><a href="https://delidoor.com.au/policies/terms-of-service" className="underline" target="_blank" rel="noopener noreferrer">Terms</a></div>
                    </div>
                </div>
            </div>
            <div className="fixed right-0 h-full w-1/2 hidden xl:inline with-background">
                <div className="flex flex-col justify-center items-center h-full">
                    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 350" width="400">
                        <path fillRule="evenodd" fill="#383839" d="M158.1 39C175.5 39 176 39.1 178.7 41.4L181.5 43.8L181.5 303.2L178.7 305.6C176.2 307.7 174.9 308 167.1 308C155.8 308 154.3 306.9 151 296.6C149.7 292.4 148.3 289 147.8 289C147.4 289 147 289.4 147 289.9C147 291.6 133 301.9 126.7 304.9C108.3 313.6 87.3 313.5 68.5 304.6C46.3 294.1 30.6 272.5 26 246.4C24.2 236.2 25.2 215.6 28 206C34.1 184.9 48.3 167.2 66 158.7C78 153 86.9 151 100.8 151C111.1 151 117.9 152.2 132.8 156.6L135 157.2L135 43.1L137.6 41.1C140.1 39.2 141.6 39 158.1 39ZM95.3 193.5C71 201 61.1 232.5 76.1 254.9C84.7 267.8 99.5 273 114.8 268.5C121.3 266.5 129.9 258.8 133.4 251.8C136 246.7 136 246.3 136 223.4L136 200.1L129.8 197.2C126.3 195.5 121.1 193.7 118.2 193.1C111.2 191.6 100.5 191.8 95.3 193.5Z" />
                        <path fillRule="evenodd" fill="#383839" d="M379.4 40.2C378.2 40.7 376.7 42.3 376.1 43.6C374.5 47.1 374.5 299.9 376.1 303.3C377.9 307.4 381 308 398.6 308C416.5 308 420.2 307.1 421.4 302.8C422.5 298.4 422.1 46.3 420.9 43.7C420.3 42.3 418.7 40.7 417.3 40.1C414.2 38.7 382.3 38.7 379.4 40.2Z" />
                        <path fillRule="evenodd" fill="#383839" d="M673.7 40C690.6 40 693.6 40.2 695.2 41.7C696.9 43.2 697 48.6 697 173.5L697 303.7L694.9 305.6C693.2 307.1 691 307.6 684.1 307.8C672.4 308.2 671.2 307.4 667.7 297.5C666.2 293.2 664.7 289.4 664.5 289.2C664.3 288.9 661.3 291.2 658 294.3C651.3 300.5 639 307.1 629.5 309.5C621.9 311.4 606.4 311.4 598 309.5C594.4 308.6 587.7 306.1 583 303.8C576 300.3 572.8 297.9 565 290.1C554.2 279.1 548.9 270.4 544.4 255.5C541.9 247.3 541.6 245.1 541.6 231C541.6 218.2 542 214.1 543.8 207.6C550 185.4 563.8 167.8 582.4 158.6C594.3 152.7 600.5 151.5 617.5 151.6C630.8 151.6 633.5 152 641.3 154.3C646.1 155.8 650.2 157 650.5 157C650.8 157 651 131.7 651 100.7C651 51.4 651.2 44.2 652.6 42.2C654.1 40.1 654.6 40 673.7 40ZM608.9 194.3C598.8 198.5 593.1 204.1 588.3 214.7C585.1 221.7 584.1 233.1 586 241.2C590.7 261 607 272.7 625.6 269.8C636 268.2 644.5 261.9 649.5 252.1C652 247.1 652 246.7 652 223.6L652 200L647 197.5C644.3 196.1 639.5 194.3 636.3 193.5C627.5 191.2 615.7 191.6 608.9 194.3Z" />
                        <path fillRule="evenodd" fill="#383839" d="M305.2 64.1C299.3 66.4 239 103.2 238 105C237.1 106.7 237.7 108.7 241.3 116.4C246.3 126.8 247.6 127.8 253.6 125.6C265.5 121.4 321.8 95.7 323.3 93.8C324.5 92.4 325 90.6 324.6 89C323.9 85.4 313.4 66.9 310.9 64.7C308.5 62.8 308.4 62.8 305.2 64.1Z" />
                        <path fillRule="evenodd" fill="#383839" d="M473.5 65.6C463.6 69.1 456.6 79.4 456.6 90.4C456.7 110.2 476.4 122.4 494.2 113.7C504.4 108.7 509.8 99.2 508.7 88C508.1 80.9 505.2 75.2 499.9 70.7C492.6 64.4 482.4 62.4 473.5 65.6Z" />
                        <path fillRule="evenodd" fill="#383839" d="M301.4 153C333 161.1 354 188.5 354 221.6C354 232.9 353 236.2 349.3 237.9C347.7 238.7 331.6 239 297.5 239L248.1 239L248.6 242.2C249.6 248.2 254.9 257.6 259.6 262C272.8 274.1 291.7 275.2 310.8 265C321.6 259.1 322 259.2 332.3 271C343.2 283.4 344 285.1 341.3 288.8C336.3 295.6 319.5 305.3 307.4 308.4C262.5 319.9 222.3 298.6 208.4 256C205.8 248.2 205.6 246.2 205.6 232C205.5 213.6 206.6 208.3 214 193.4C224.2 172.7 243.6 157.2 265.5 152.5C275 150.4 292.3 150.6 301.4 153ZM253.5 205.2C250.5 212.4 248.8 212 281 212L310 212L309.4 209.2C307.3 199.5 298.8 189.8 290 187.1C275.1 182.7 259.8 190.3 253.5 205.2Z" />
                        <path fillRule="evenodd" fill="#383839" d="M814.2 152.5C829.7 155.7 841.2 162.2 853.5 174.5C860.8 181.7 863.5 185.3 867.2 192.5C874.9 207.4 876.5 214.1 876.5 230.5C876.5 242 876.1 245.9 874.2 252.3C868 273.9 853 292.7 834.2 302.3C820.2 309.5 815.8 310.5 798.5 310.5C784.7 310.4 782.8 310.2 775.5 307.7C754.8 300.5 736.3 283.5 727.4 263.5C721.6 250.2 719.4 230.3 722.1 215.4C727.5 186.2 752.2 160.1 781.1 153C790.2 150.7 805 150.5 814.2 152.5ZM787.8 193.4C777.4 197.1 770.6 203.4 765.6 214C762.7 220 762.5 221.3 762.5 231C762.5 240.7 762.7 242 765.7 248.2C776.9 271.8 804.8 277.6 823.3 260.1C844.4 240.1 837.7 204.1 811 193.9C805.1 191.7 793.4 191.4 787.8 193.4Z" />
                        <path fillRule="evenodd" fill="#383839" d="M1029.6 183.5C1040 198.2 1044.4 211.9 1044.4 230.5C1044.5 244.2 1043.3 250.4 1038.6 262.2C1029.1 285.7 1007.4 304.1 982.7 309.5C971 312.1 954.2 311.2 943.2 307.5C917.7 298.9 897.8 277.2 890.9 250.5C888 239.4 888.1 222.5 890.9 211.5C898.6 182.1 920.5 160.1 949 153C978.6 145.7 1011.4 158.1 1029.6 183.5ZM954.9 193.6C944.7 197.1 935 207.5 931.5 218.5C930.2 222.7 929.9 226.4 930.2 233C930.6 240.7 931.1 242.8 934.3 249.3C950.8 282.7 996.2 274.6 1003.2 237.1C1004.6 229.5 1003.2 220.4 999.4 212.6C996.1 205.8 987.8 197.7 981.3 194.8C974.9 191.9 961.4 191.3 954.9 193.6Z" />
                        <path fillRule="evenodd" fill="#383839" d="M1132.1 152.5C1123.5 154.9 1117 158.9 1109.1 166.8C1105 170.8 1101.6 173.9 1101.5 173.8C1101.4 173.6 1100.5 171 1099.5 168C1097.4 161.5 1095.1 156.8 1093.6 155.8C1093 155.4 1087.9 155 1082.3 155C1066.3 155 1068 146 1068 230.7C1068.1 288 1068.3 302.8 1069.4 304.2C1071.7 307.2 1075.8 308 1090.3 308C1104.6 308 1110.7 307.1 1112.2 304.8C1112.6 304.1 1113 281.8 1113 255.2L1113 206.9L1117.4 202.2C1124.3 194.9 1129.8 192.5 1139.5 192.6C1143.9 192.7 1149.8 193 1152.6 193.5C1157.5 194.2 1157.8 194.1 1159.7 191.4C1164.9 183.8 1175 164.8 1175 162.5C1175 159.1 1171.1 155.6 1165.2 153.5C1158.5 151.1 1139.2 150.5 1132.1 152.5Z" />
                        <path fillRule="evenodd" fill="#383839" d="M464.3 155C463.1 155.5 461.7 156.8 461.1 157.9C460.3 159.2 460 182.7 460 231.5L460 303.1L462.5 305.6L465 308.1L483.6 307.8C501.7 307.5 502.2 307.4 504.1 305.1C505.9 302.9 506 300.2 506 231C506 161.8 505.9 159.1 504.1 156.9C502.2 154.6 501.8 154.5 484.3 154.3C474.5 154.2 465.5 154.5 464.3 155Z" />
                    </svg>
                    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 882 192" height="55" className="mt-5">
                        <path fillRule="evenodd" fill="#a3ca81" d="m125.1 40.6c11.4 4.3 11.2 2.8 2.9 20.6l-7.2 15.6-6.4-2.9c-13-5.9-20.4-7.4-37.4-7.4-17.5 0-23 1.3-27.5 6.6-2.7 3.3-3.4 9.4-1.5 13 2.3 4.3 7.1 5.6 42.4 12.4 18.2 3.5 25 5.7 32.2 10.3 11.3 7.3 16.8 16.4 18 30 2.1 22.3-14 41.8-40.5 49.1-28 7.8-67.9 3.8-93.6-9.2l-6.4-3.2 7-15c3.9-8.3 7.4-15.3 7.8-15.8 0.4-0.4 2.7 0.3 5.2 1.7 6.4 3.5 20.5 8.3 29.9 10 10.9 2.1 29 2.1 36 0 7.2-2.1 12-6.9 12-11.9 0-2-0.5-4.5-1-5.6-2.4-4.3-10.4-6.7-36.9-10.9-24.4-3.9-36.2-8.4-45.3-17.2-7-6.8-10-13.9-10.6-25.4-0.4-7.4-0.1-10.5 1.6-16.1 5.8-19.1 24.7-32.2 52-36.2 19.8-2.9 48.3 0.2 67.3 7.5z" />
                        <path fillRule="evenodd" fill="#a3ca81" d="m276.3 33.6c11.9 2.6 21.1 7.6 29.2 15.9l7.1 7.2 6.8-6.4c7.5-6.9 19-13.2 29.1-15.9 3.3-0.8 10.7-1.8 16.5-2.1 29.7-1.6 52.9 12 61.9 36.2 4.8 12.7 5.1 17.4 5.1 71.1v50.4h-21.9-21.9l-0.4-46.8c-0.4-39.5-0.7-47.5-2.1-51.7-2.6-7.9-7-14-12.4-17.2-4.5-2.6-5.5-2.8-15.8-2.8-9.4 0-11.7 0.3-15.6 2.3-9.3 4.5-15.7 13.6-17.9 25.2-0.6 3-1 23.5-1 48.1v42.9h-22.5-22.5v-45.8c0-50-0.3-53.2-5.8-62.2-1.6-2.6-4.8-5.7-7.6-7.4-4.3-2.7-5.9-3.1-14.5-3.4-7.9-0.3-10.6 0-15.1 1.7-10.2 3.8-17.6 13.5-20 26.1-0.6 3-1 23.5-1 48.1v42.9h-22.5-22.5v-78-78h21.5 21.5v9.1 9.1l5.3-4.8c6.2-5.6 18.8-11.9 27.7-13.8 8.8-1.9 22.6-1.9 31.3 0z" />
                        <path fillRule="evenodd" fill="#a3ca81" d="m563.5 34.9c28.7 7.6 44.1 25.3 47.5 54.6 0.5 4.9 1 29.6 1 54.7v45.8h-42v-19.4l-4.9 5.5c-5.4 6-13.1 10.6-22.6 13.6-4.7 1.4-9.2 1.8-21 1.7-13.5 0-15.8-0.3-23.1-2.7-23-7.6-35.6-24.9-34.1-46.7 0.9-13.7 7.1-24.8 17.8-31.9 13.5-9 24.7-11.1 59.7-11.1h25.5l-0.7-5.4c-1.7-15.1-10.6-23.2-28.1-25.7-14.3-1.9-30.8 1.4-45.4 9.2-4 2.2-7.5 3.8-7.6 3.7-0.1-0.2-3.6-7-7.9-15.2-8.8-17.1-9.2-15 3.9-21.5 9.1-4.6 22.2-8.6 34.5-10.6 12.4-2.1 37-1.3 47.5 1.4zm3.5 89.1l-15.7 0.1c-26.9 0.3-33.6 1.6-39.4 7.5-3.3 3.4-3.4 3.8-3.4 11.4 0 7.4 0.2 8.1 3 11 5.8 6.2 9.8 7.5 22 7.5 9.1 0 11.8-0.4 15.7-2.2 7.2-3.3 11.7-7.1 14.9-12.7 2.6-4.6 2.9-6 2.9-13.9z" />
                        <path fillRule="evenodd" fill="#a3ca81" d="m744.3 32.1l4.7-0.1v20.5 20.5h-9.2c-18.8 0-30.6 6.3-37.1 19.5-4.3 8.7-4.7 13.2-4.7 56.5v41h-22.5-22.5v-78-78h21.5 21.5v10.2 10.2l5.3-5.1c8.8-8.6 19.8-14 32.9-16.2 2.9-0.5 7.4-0.9 10.1-1z" />
                        <path fillRule="evenodd" fill="#a3ca81" d="m788 0h22 22v19 19h19.5 19.5v17 17h-19.5-19.5v32.5c0 19.6 0.4 34.4 1.1 37.5 1.2 5.6 4.5 10.2 9.3 12.7 4.7 2.5 15.8 2.2 22.4-0.7 2.9-1.2 5.4-2.1 5.6-1.9 0.2 0.2 2.9 7.1 6 15.3 4.6 11.8 5.5 15.1 4.4 15.8-15.7 10.6-47 11.7-66.3 2.3-11.4-5.5-18.8-14-23.7-27.1-2.1-5.7-2.2-7.5-2.6-46.2l-0.3-40.2h-12-11.9v-17-17h12 12v-19z" />
                    </svg>
                </div>
            </div>
        </div>
    )
}